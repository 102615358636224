import { createSlice } from '@reduxjs/toolkit';
import { ServiceParams } from '@wearemojo/api-client';
import { ActivityMetadataType } from '@wearemojo/sanity-schema';

import { AppState } from '.';

type Event = {
	action: 'recordActivityCompleted';
	data: {
		payload: ServiceParams['learntracking']['recordActivityCompleted']['params'];
		analyticsPayload: {
			title: string;
			metadata?: ActivityMetadataType;
		};
	};
};

export type LoggedOutEvents = {
	events: Event[];
};

const initialState: LoggedOutEvents = {
	events: [],
};

const loggedOutEventsSlice = createSlice({
	name: 'loggedOutEvents',
	initialState,
	reducers: {
		addLoggedOutEvent: (state, action: { payload: Event }) => {
			const event = action.payload;
			state.events.push(event);
		},
		resetLoggedOutEvents: (state) => {
			state.events = [];
		},
	},
});
export const { addLoggedOutEvent, resetLoggedOutEvents } =
	loggedOutEventsSlice.actions;
export const selectLoggedOutEvents = (state: AppState) =>
	state.loggedOutEvents.events;

export default loggedOutEventsSlice;
