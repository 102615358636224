import { ServiceParams } from '@wearemojo/api-client';
import { ActivityMetadataType } from '@wearemojo/sanity-schema';
import { useCallback } from 'react';

import { AnalyticsEvent } from '../../../analytics';
import { useTrackEvent } from '../../../analytics/trackEvent';
import useEndpointMutation from '../../../hooks/queries/useEndpointMutation';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useLocalization } from '../../../hooks/useLocalization';
import { selectUserId } from '../../session';
import api from '..';

type RecordActivityPayload =
	ServiceParams['learntracking']['recordActivityCompleted']['params'];

type RecordActivityLearningCompletedPayload = {
	activityId: string;
};

type RecordActivityAnalyticsPayload = {
	title: string;
	metadata?: ActivityMetadataType;
};

const useRecordActivity = () => {
	const { timezone } = useLocalization();
	const userId = useAppSelector(selectUserId);
	const trackEvent = useTrackEvent();

	const [
		recordActivityCompletedMutation,
		{ isLoading: isRecordingActivityCompleted },
	] = useEndpointMutation(api.endpoints.recordActivityCompleted.useMutation());
	const [
		recordActivityLearningCompletedMutation,
		{ isLoading: isRecordingActivityLearningCompleted },
	] = useEndpointMutation(
		api.endpoints.recordActivityLearningCompleted.useMutation(),
	);

	const recordActivityCompleted = useCallback(
		async (
			payload: RecordActivityPayload,
			analyticsPayload: RecordActivityAnalyticsPayload,
		) => {
			if (!userId) return;

			trackEvent(AnalyticsEvent.activity_completed, {
				activityId: payload.activityId,
				title: analyticsPayload.title,
				metadata: analyticsPayload.metadata
					? formatMetadata(analyticsPayload.metadata)
					: undefined,
				variantId: payload.activityVariantId,
				context: payload.context,
			});

			return await recordActivityCompletedMutation({
				userId,
				timezone,
				params: {
					activityId: payload.activityId,
					activityVariantId: payload.activityVariantId,
					context: payload.context,
					// netCoins: 1, // TODO: give the people some coins!
				},
			});
		},
		[recordActivityCompletedMutation, timezone, userId, trackEvent],
	);

	const recordActivityLearningCompleted = useCallback(
		async (
			payload: RecordActivityLearningCompletedPayload,
			analyticsPayload: RecordActivityAnalyticsPayload,
		) => {
			if (!userId) return;

			trackEvent(AnalyticsEvent.activity_learning_completed, {
				activity_id: payload.activityId,
				title: analyticsPayload.title,
				metadata: analyticsPayload.metadata
					? formatMetadata(analyticsPayload.metadata)
					: undefined,
			});

			return await recordActivityLearningCompletedMutation({
				userId,
				timezone,
				params: {
					activityId: payload.activityId,
				},
			});
		},
		[recordActivityLearningCompletedMutation, timezone, userId, trackEvent],
	);

	return {
		recordActivityCompleted,
		recordActivityLearningCompleted,
		isLoading:
			isRecordingActivityCompleted || isRecordingActivityLearningCompleted,
	};
};

const formatMetadata = (metadata: ActivityMetadataType) => {
	return metadata.reduce((acc: Record<string, string>, curr) => {
		acc[curr.key] = curr.value;
		return acc;
	}, {});
};

export default useRecordActivity;
