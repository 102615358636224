const VIDEO_FRAGMENT = `
	...,
	poster {
		asset->,
	},
`;

export const BLOCK_CONTENT_FRAGMENT = `
	_type != 'reference' => {
		...,
	},
	_type == 'reference' => @-> {
		...,
		_type == 'Audio' => {
			file {
				asset->,
			},
		},
		_type == 'Video' => {
			${VIDEO_FRAGMENT}
		},
		_type == 'Animation' => {
			lottieFile {
				asset->,
			},
			accompanyingAudio {
				asset->,
			},
		},
		_type == 'Carousel' => {
			tiles[] {
				...,
				image {
					asset->,
				},
			},
		},
	},
	_type == 'ContentCardBlock' => {
		content->,
		image {
			asset->,
		},
	},
	_type == 'ContentCardList' => {
		...,
		cards[] {
			content->,
		},
	},
	_type == 'Flair' => {
		image {
			asset->,
		},
	},
	_type == 'image' => {
		asset->,
	},
	_type == 'TileWithExplanationList' => {
		items[] {
			...,
			image {
				asset->,
			},
		},
	},
	_type == 'VideoKeyPoints' => {
		video-> {
			${VIDEO_FRAGMENT}
		},
	},
	_type == 'ExpertQuote' => {
		...,
		authorImage {
			asset->,
		}
	},
	_type == 'QuoteCardBlock' => {
		...,
		content->,
		authorImage {
			asset->,
		},
	},
	_type == 'ContentSwitcherBlock' => {
		dynamicContent[] {
			...,
			content[] {
				_type != 'reference' => {
					...,
				},
				_type == 'reference' => @->,
			},
		},
	},
`;

export const HELP_FRAGMENT = `
	...,
	tabs[] {
		...,
		content {
			_type == 'reference' => @-> {
				...,
				content[] {
					${BLOCK_CONTENT_FRAGMENT}
				},
			},
		},
	},
`;
