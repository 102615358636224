import {
	ParamListBase,
	RouteProp,
	useNavigation,
	useRoute,
	useScrollToTop,
} from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
	ConnectIcon,
	ExploreIcon,
	getComponentName,
	HeadphonesIcon,
	LinkProviderOptions,
	NavBarLink,
	RootView,
	TodayIcon,
} from '@wearemojo/ui-components';
import { ComponentType, useCallback, useMemo, useRef } from 'react';
import { Platform, ScrollView } from 'react-native';

import useAppDispatch from '../hooks/useAppDispatch';
import useCommunityExplainedShown from '../hooks/useCommunityExplainedShown';
import useEroticStoriesTabFeature from '../hooks/useEroticStoriesTabFeature';
import { setIsMobileNavOpen } from '../store/navigation';
import { createLinkOnPress, createLinkTo } from './LinkProvider';
import NavigatorKey from './NavigatorKey';
import { RootParamList } from './params';
import ScreenKey from './ScreenKey';

export const createScreenLinkTo = (
	screen: ScreenKey,
	params?: any,
	options?: LinkProviderOptions,
) => {
	const to = getToParams(screen, params);
	return createLinkTo<RootParamList>(to as any, options); // @TOOD fix typing
};

// Convenience component for the above
export const ScreenLinkProvider = ({
	screen,
	params,
	options,
	children,
}: {
	screen: ScreenKey;
	params?: any;
	options?: LinkProviderOptions;
	children: React.ReactNode;
}) => {
	const LinkProvider = createScreenLinkTo(screen, params, options);
	return <LinkProvider>{children}</LinkProvider>;
};

export type ScreenParams = Record<string, string> & {
	/*
	In order for the behavior of tab bars to work as expected, we always
	expect the first screen in a navigator to be considerd the initial.
	This prop stops it being inferred as otherwise.
	https://reactnavigation.org/docs/nesting-navigators/#rendering-initial-route-defined-in-the-navigator
	*/
	initial: boolean;
};

export type GetToParams = {
	screen: string;
	params?: (GetToParams | ScreenParams) | undefined;
};

export const getToParams = (screen: ScreenKey, params?: any): GetToParams => {
	switch (screen) {
		// RootParamList
		case ScreenKey.AuthRecoverAccount:
		case ScreenKey.AuthRedirectApple:
		case ScreenKey.AuthRedirectMagicLink:
		case ScreenKey.Auth:
		case ScreenKey.AuthMobileIntro:
		case ScreenKey.AuthRegisterPreface:
		case ScreenKey.Consent:
		case ScreenKey.CelebrateCheckout:
		case ScreenKey.AppNudge:
		case ScreenKey.AppReview:
		case ScreenKey.DiscourseSso:
		case ScreenKey.GoNative:
		case ScreenKey.GoWeb:
		case ScreenKey.GroupSessions:
		case ScreenKey.EfficacyQuestions:
		case ScreenKey.NotFound:
		case ScreenKey.Welcome:
		case ScreenKey.HomeRedirect:
		case ScreenKey.CommunityCreateTopic:
		case ScreenKey.CommunityTopic:
		case ScreenKey.CommunityExpertNote:
		case ScreenKey.StreaksCalendar:
		case ScreenKey.Refer:
		case ScreenKey.SwapActivityQuestions:
			return { screen, params };

		case ScreenKey.AccountSettings:
		case ScreenKey.AccountManageBilling:
		case ScreenKey.AccountPreferences:
		case ScreenKey.AccountLegal:
		case ScreenKey.Account:
			return {
				screen: NavigatorKey.AccountNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		case ScreenKey.Home:
		case ScreenKey.Community:
			return {
				screen: NavigatorKey.MainNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		case ScreenKey.EroticStories:
			return {
				screen: NavigatorKey.MainNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		// ExploreParamList
		case ScreenKey.Explore:
			return {
				screen: NavigatorKey.MainNavigator,
				params: {
					initial: false,
					screen: NavigatorKey.ExploreNavigator,
					params: {
						initial: false,
						screen,
						params,
					},
				},
			};

		case ScreenKey.ActivityStaging:
		case ScreenKey.ActivityLearning:
		case ScreenKey.ActivityVariant:
		case ScreenKey.ActivityHelp:
			return {
				screen: NavigatorKey.ActivityNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		// SoundParamList
		case ScreenKey.SoundSeries:
		case ScreenKey.SoundEpisode:
			return {
				screen: NavigatorKey.SoundNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		// CancelationParamList
		case ScreenKey.CancelationQuestion:
		case ScreenKey.CancelationSubQuestion:
		case ScreenKey.CancelationInvestorFriendlyQuestion:
		case ScreenKey.CancelationDiscountOffer:
		case ScreenKey.CancelationSuccess:
		case ScreenKey.CancelationDiscountApplied:
		case ScreenKey.CancelationNoDiscount:
		case ScreenKey.PostCancelationQuestion:
		case ScreenKey.PostCancelationSuccess:
			return {
				screen: NavigatorKey.CancelationNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		// CheckoutParamList
		case ScreenKey.Checkout:
		case ScreenKey.CheckoutPreface:
		case ScreenKey.CheckoutTrialOptions:
		case ScreenKey.CheckoutSubscribe:
		case ScreenKey.CheckoutSubscriptionPlans:
		case ScreenKey.CheckoutPayment:
		case ScreenKey.CheckoutRedirect:
		case ScreenKey.CheckoutSuccess:
			return {
				screen: NavigatorKey.CheckoutNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		// StreaksParamList
		case ScreenKey.StreakGained:
		case ScreenKey.StreaksCommitment:
			return {
				screen: NavigatorKey.StreaksNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		// DevParamList
		case ScreenKey.DevCMSContent:
		case ScreenKey.DevWhoops:
			return {
				screen: NavigatorKey.DevNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};
		case ScreenKey.ChangePollResponses:
			return {
				screen: NavigatorKey.DevNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};
		case ScreenKey.ContentValidation:
			return {
				screen: NavigatorKey.DevNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};

		// SQScoreParamList
		case ScreenKey.SQScorePillarList:
		case ScreenKey.SQScorePillar:
			return {
				screen: NavigatorKey.SQScoreNavigator,
				params: {
					initial: false,
					screen,
					params,
				},
			};
	}

	const assertUnreachable = (_: never): never => {
		throw new Error(
			`Unable to generate params to screen ("${screen}", params: ${JSON.stringify(
				params,
			)})`,
		);
	};

	/*
	Expected to be unreachable. Slight hack to tell TS to detect incomplete switch case handling.
	An error below (not assignable to parameter of type 'never') indicates a screen hasn't been accounted for.
	*/
	assertUnreachable(screen);
};

export const useMainNavLinks = (): NavBarLink[] => {
	const route = useRoute();
	const { communityExplainedShown } = useCommunityExplainedShown();

	const enableEroticStoriesTab =
		useEroticStoriesTabFeature().isEroticStoriesTabOn;

	const dispatch = useAppDispatch();
	const onPressEffect = useCallback(() => {
		if (Platform.OS === 'web') {
			dispatch(setIsMobileNavOpen(false));
		}
	}, [dispatch]);

	return useMemo(() => {
		const home = {
			title: 'Today',
			key: ScreenKey.Home,
			icon: TodayIcon,
			linkProvider: createScreenLinkTo(ScreenKey.Home, {}, { onPressEffect }),
			getToParams: getToParams(ScreenKey.Home),
			isActive: isMatchingMainNavigatorRoute(route, [ScreenKey.Home]),
		};

		const explore = {
			title: 'Explore',
			key: NavigatorKey.ExploreNavigator,
			icon: ExploreIcon,
			linkProvider: createScreenLinkTo(
				ScreenKey.Explore,
				{},
				{ onPressEffect },
			),
			getToParams: getToParams(ScreenKey.Explore),
			isActive: isMatchingMainNavigatorRoute(route, [
				NavigatorKey.ExploreNavigator,
			]),
		};

		const eroticStories = {
			title: 'Stories',
			key: ScreenKey.EroticStories,
			icon: HeadphonesIcon,
			linkProvider: createScreenLinkTo(
				ScreenKey.EroticStories,
				{},
				{ onPressEffect },
			),
			getToParams: getToParams(ScreenKey.EroticStories),
			isActive: isMatchingMainNavigatorRoute(route, [ScreenKey.EroticStories]),
		};

		const community = {
			title: 'Connect',
			key: ScreenKey.Community,
			icon: ConnectIcon,
			linkProvider: createScreenLinkTo(
				ScreenKey.Community,
				{},
				{ onPressEffect },
			),
			getToParams: getToParams(ScreenKey.Community),
			isActive: isMatchingMainNavigatorRoute(route, [ScreenKey.Community]),
			hasActivity: !communityExplainedShown.isDone,
		};

		if (enableEroticStoriesTab) {
			return [home, explore, eroticStories, community];
		}

		return [home, explore, community];
	}, [
		onPressEffect,
		route,
		communityExplainedShown.isDone,
		enableEroticStoriesTab,
	]);
};

const isMatchingMainNavigatorRoute = (
	route: RouteProp<ParamListBase, string>,
	names: string | string[],
): boolean => {
	const routeName =
		route.name === NavigatorKey.MainNavigator
			? (route.params as { screen: string } | undefined)?.screen
			: route.name;
	return routeName ? [names].flat().includes(routeName) : false;
};

export function withMainNavigatorView<P extends JSX.IntrinsicAttributes>(
	Component: ComponentType<P>,
) {
	function WithMainNavigatorView(props: P) {
		const scrollViewRef = useRef<ScrollView>(null);
		useScrollToTop(scrollViewRef);

		return useMemo(
			() => (
				<RootView.WithScroll scrollViewRef={scrollViewRef}>
					<Component {...props} />
				</RootView.WithScroll>
			),
			[props],
		);
	}

	WithMainNavigatorView.displayName = getComponentName(Component);

	return WithMainNavigatorView;
}

export const useAccountLinkProvider = () => {
	const dispatch = useAppDispatch();
	const onPressEffect = useCallback(() => {
		if (Platform.OS === 'web') {
			dispatch(setIsMobileNavOpen(false));
		}
	}, [dispatch]);

	return useMemo(
		() => createScreenLinkTo(ScreenKey.Account, {}, { onPressEffect }),
		[onPressEffect],
	);
};

// In some instances we want to just generate a LinkProvider with just an
// onPress handler for linking to a screen (e.g. no resolution of href on web)
// One bonus is this generated LinkProvider will not require navigation context
// (useful for usages that escape the navigation provider, e.g. whoops modal)
export const useCreateScreenLinkOnPress = () => {
	const navigation = useNavigation<NativeStackNavigationProp<RootParamList>>();

	return useCallback(
		(
			screen: ScreenKey,
			params?: any,
			options?: {
				onPressEffect?: () => void;
			},
		) => {
			const onPress = () => {
				const to = getToParams(screen, params);
				// @ts-ignore
				navigation.navigate(to.screen, to.params);
				options?.onPressEffect?.();
			};

			return createLinkOnPress(onPress);
		},
		[navigation],
	);
};
